<template>
<v-container v-if="show">
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th>Email</th>
                    <th>Company Name</th>
                    <th>Phone Number</th>
                    <th>Company Website</th>
                    <th>Category</th>
                    <th class="text-center">Status</th>
                </tr>
            </thead>
            <tbody>
                <!-- eslint-disable-next-line -->
                <tr v-for="item in csv_data">
                    <td><quick-edit v-model="item.email" :placeholder="'Client Name'" ></quick-edit></td>
                    <td><quick-edit v-model="item.company_name" :placeholder="'Comapny Name'" ></quick-edit></td>
                    <td><quick-edit v-model="item.phone_number" :placeholder="'Phone Number'" ></quick-edit></td>
                    <td><quick-edit v-model="item.link_to_company" :placeholder="'Company Link'" ></quick-edit></td>
                    <td><quick-edit v-model="item.category" :placeholder="'Bussiness Category'" ></quick-edit></td>
                    <td class="text-center">
                        <span v-if="!(Unsubscribers.indexOf(item.email) > -1)" class="material-icons" title="Subscribed">notifications</span>
                        <span v-if="Unsubscribers.indexOf(item.email) > -1" class="material-icons" title="Un-Subscribed" style="color: #FF5D6D">notifications_off</span>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
    <hr style="opacity:0.3">
    <div class="actions">
        <v-btn class="elevation-0" to="/">Cancel</v-btn>
        <v-btn class="elevation-0" @click="openScheduler" color="accent"><v-progress-circular
            indeterminate
            color="primary"
            v-if="saveCampaignInProgress"
        ></v-progress-circular><span v-if="!saveCampaignInProgress">Schedule</span></v-btn>

        <v-btn class="elevation-0 primary" :disabled='saveCampaignInProgress' @click="saveCampaign" id="SendEmail"><v-progress-circular
            indeterminate
            color="primary"
            v-if="saveCampaignInProgress"
        ></v-progress-circular><span v-if="!saveCampaignInProgress">Create</span></v-btn>
    </div>
    <v-container fill-height class="scheduler" v-if="showScheduler">
        <template>
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md6>
                    <v-row no-gutters class="currentTime">
                        <v-col cols="12" sm="12">
                            You can only choose time above 10 mins from now {{getTime}}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6">
                            <v-date-picker
                                v-model="scheduleDate"
                                color="#1976D2"
                                full-width
                                :min="calendarMinDate"
                                show-adjacent-months
                            ></v-date-picker>
                        </v-col>
                        <v-col cols="12" sm="6"> 
                            <v-time-picker
                                format="ampm"
                                ampm-in-title
                                v-model="scheduleTime"
                                :min="calendarMinTime"
                            ></v-time-picker>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6">
                            <v-btn @click="cancelScheduler" class="elevation-0">Cancel</v-btn>
                        </v-col>
                        <v-col cols="12" sm="6">
                                <v-btn @click="saveCampaign" class="elevation-0 primary" :disabled='saveCampaignInProgress'><v-progress-circular
                                indeterminate
                                color="primary"
                                v-if="saveCampaignInProgress"
                            ></v-progress-circular><span v-if="!saveCampaignInProgress">Schedule</span></v-btn>
                        </v-col>
                    </v-row>
                </v-flex>
            </v-layout>
        </template>
    </v-container>
</v-container>
</template>
<script>
export default {
    name: 'AddNewCampaign',
    data(){
        return{
            csv_data: null,
            show: false,
            showScheduler: false,
            calendarMinDate: '',
            calendarMinTime: '',
            scheduleDate: '',
            scheduleTime: '',
            getTime: ''
        }
    },
    mounted(){
        if(this.$store.state.user == null || this.$store.state.user == undefined){
            this.$router.push({path: '/sign-in'});
        }
        else{
            if(this.$store.state.csv_data){
                var _this = this;
                this.csv_data = this.$store.state.csv_data;
                this.$store.state.campaignTitle = '';
                this.$store.state.campaignTemplate = '';
                this.addSubscriptionStatus();
                this.getCalendarDefaultTime(new Date());
                setInterval(function(){
                    _this.currentTime(new Date());
                    _this.getCalendarMinTIme(new Date());
                    _this.getCalendarMinDate(new Date());
                }, 1000);
                this.show = true;
            }
            else{
                this.$router.push({path: '/'});
            }
        }
    },
    methods:{
        currentTime(date){
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var seconds = date.getSeconds();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            hours = hours < 10 ? '0'+hours : hours;
            minutes = minutes < 10 ? '0'+minutes : minutes;
            seconds = seconds < 10 ? '0'+seconds : seconds;
            var strTime = hours + ':' + minutes + ':' + seconds + ' ' +ampm;
            this.getTime = strTime;
        },
        addSubscriptionStatus(){
            var CSVdata = this.csv_data;
            var _this = this;
            CSVdata.forEach(function(item, index, object) {
                if(item.phone_number == '' && item.category == '' && item.link_to_company == '' && item.company_name == ''){
                    object.splice(index, 1);
                }
                else{
                    if(_this.Unsubscribers.indexOf(item.email) > -1){
                        item.status = 'un-subscribed';
                    }
                    else{
                        item.status = 'subscribed';
                    }
                }
            });
            console.log(this.csv_data);
        },
        saveCampaign(){
            var name = this.$store.state.campaignTitle;
            var campaignTemplate = this.$store.state.campaignTemplate;
            if(name.length < 3){
                this.$store.state.responseMsg = 'Please enter a Campaign Name with minimum 3 characters';
                this.$store.state.ResponseClass = 'error';
            }
            else if(campaignTemplate == ''){
                this.$store.state.responseMsg = 'Please select an email template for the campaign';
                this.$store.state.ResponseClass = 'error';
            }
            else{
                this.$store.state.saveCampaignInProgress = true;
                var createCampaignData;
                
                if(this.showScheduler == true){
                    if(this.scheduleTime != '' && this.scheduleDate != ''){
                        var final_time = this.scheduleDate+' '+this.scheduleTime;
                        createCampaignData = {
                            "user" : this.$store.state.user.attributes.email,
                            "status": "SCHEDULED",
                            "name": name,
                            "template_name": campaignTemplate,
                            "clients": this.csv_data,
                            "send_time": new Date(final_time).getTime()
                        };
                        this.$store.dispatch('saveCampaign', createCampaignData);
                    }
                    else{
                        console.log('step3');
                        this.$store.state.responseMsg = 'Please select date and time';
                        this.$store.state.ResponseClass = 'error';
                        this.$store.state.saveCampaignInProgress = false;
                    }
                }
                else{
                    createCampaignData = {
                        "user" : this.$store.state.user.attributes.email,
                        "status": "PENDING",
                        "name": name,
                        "template_name": campaignTemplate,
                        "clients": this.csv_data
                    };
                    this.$store.dispatch('saveCampaign', createCampaignData);
                }
            }
        },
        CheckSubscribtion(needle) {
            // Per spec, the way to identify NaN is that it is not equal to itself
            var findNaN = needle !== needle;
            var indexOf;

            if(!findNaN && typeof Array.prototype.indexOf === 'function') {
                indexOf = Array.prototype.indexOf;
            } else {
                indexOf = function(needle) {
                    var i = -1, index = -1;

                    for(i = 0; i < this.length; i++) {
                        var item = this[i];

                        if((findNaN && item !== item) || item === needle) {
                            index = i;
                            break;
                        }
                    }

                    return index;
                };
            }

            return indexOf.call(this, needle) > -1;
        },
        getCalendarMinDate(date){
            var month = date.getMonth()+1;
            var day = date.getDate();
            if(month < 10){
                month = '0'+month;
            }
            if(day<10){
                day = '0'+day;
            }
            this.calendarMinDate = date.getFullYear()+'-'+month+'-'+day;
        },
        getCalendarMinTIme(time){
            var hour = time.getHours();
            var min = time.getMinutes()+10;
            if(min >= 60){
                min = min - 60;
                hour = hour + 1;
                if(hour>24){
                    hour = 0;
                }
            }
            if(min < 10){
                min = '0'+min;
            }
            this.calendarMinTime = hour+':'+min;
        },
        getCalendarDefaultTime(time){
            var defaultMin = time.getMinutes()+30;
            var defaulthr = time.getHours();
            if(defaultMin >= 60){
                defaultMin = defaultMin - 60;
                defaulthr = defaulthr + 1;
                if(defaulthr>=24){
                    defaulthr = 0;
                }
            }
            this.scheduleTime = defaulthr+':'+defaultMin;
        },
        openScheduler(){
            var name = this.$store.state.campaignTitle;
            var campaignTemplate = this.$store.state.campaignTemplate;
            if(name.length < 3){
                this.$store.state.responseMsg = 'Please enter a Campaign Name with minimum 3 characters';
                this.$store.state.ResponseClass = 'error';
            }
            else if(campaignTemplate == ''){
                this.$store.state.responseMsg = 'Please select an email template for the campaign';
                this.$store.state.ResponseClass = 'error';
            }
            else{
                this.showScheduler = true;
            }
        },
        cancelScheduler(){
            this.showScheduler = false;
            this.scheduleDate= '';
            this.scheduleTime= '';
        },
    },
    computed: {
        saveCampaignInProgress(){
            return this.$store.state.saveCampaignInProgress;
        },
        Unsubscribers(){
            return this.$store.state.unSubscribers;
        },
    },
    
}
</script>
<style scoped>
.actions{
    text-align: right;
    margin: 20px 0;
}
.actions button.elevation-0, .actions a.elevation-0{
    padding: 20px 60px;
    margin-left: 15px;
}
.actions button.primary, .actions a.primary{
    background-color: #1F95EE !important;
}
.scheduler{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    max-width: 100%;
    z-index: 999;
}
.scheduler .v-picker{
    border-radius: 0;
}
.scheduler button{
    min-width: 100% !important;
    border-radius: 0;
}
.scheduler .v-picker--time{
    width: 100%;
}
.v-picker--time>>>.v-picker__title{
    padding: 9px !important;
}
.currentTime{
    background-color: #fff;
    padding: 15px;
}
</style>